import "core-js";
import "whatwg-fetch";
import React from "react";
import { hydrateRoot, createRoot } from "react-dom/client";
import App from "./app";
import { BrowserRouter } from "react-router-dom";

// Styling
import "../../../scss/style-rbx-company.scss";

// Redux
import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import { appReducer } from "@js/app-reducer";
import { timelineReducer } from "@paragraphs/timeline/reducer-timeline";
import { calendarMonthReducer } from "@paragraphs/calendar-month/reducer-calendar-month";
import i18nReducer from "./../../i18n/i18n-slice";
import { microSiteAction } from "@js/app-actions";

// GraphQL
import { restHost, graphQlEndpoint } from "../../config";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import possibleTypes from "../../../../public/data/possibleTypes.json";
import { getCurrentLanguage } from "../../lib/lib";
import PageTransitionProvider from "@js/page-transition/page-transition-provider";

const store = createStore(
  combineReducers({
    appStore: appReducer,
    timeLine: timelineReducer,
    calendarMonth: calendarMonthReducer,
    i18n: i18nReducer,
  }),
  window.__PRELOADED_STATE__
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  link: from([
    errorLink,
    createHttpLink({
      uri: () => {
        const currentLanguage = getCurrentLanguage(window.location.pathname);

        return `${restHost}${
          currentLanguage !== "und" && currentLanguage !== "de"
            ? "/" + currentLanguage
            : ""
        }${graphQlEndpoint}`;
      },
      credentials: "same-origin",
    }),
  ]),
  cache: new InMemoryCache({
    possibleTypes,
  }).restore(window.__APOLLO_STATE__),
});

store.dispatch(microSiteAction("rbx-company"));

// Allow the passed states to be garbage-collected
delete window.__PRELOADED_STATE__;

const container = document.getElementById("app");
if (process.env.NODE_ENV === "development") {
  let root = null;
  if (!root) {
    root = createRoot(container);
  }

  root.render(
    <ApolloProvider client={client}>
      <Provider store={store}>
        <BrowserRouter forceRefresh={false}>
          <PageTransitionProvider>
            <App />
          </PageTransitionProvider>
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  );
} else {
  hydrateRoot(
    container,
    <ApolloProvider client={client}>
      <Provider store={store}>
        <BrowserRouter forceRefresh={false}>
          <PageTransitionProvider>
            <App />
          </PageTransitionProvider>
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  );
}

if (module["hot"]) {
  module["hot"].accept();
}
